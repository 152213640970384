import './Home.scss';
import Blogs from '../Blogs/Blogs';

const Home = () => {
    return(
        <div className="homepage-container">
            <Blogs/>
        </div>
    )
}

export default Home