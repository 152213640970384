import './App.scss';
import AppRouter from './Router/AppRouter';
import Header from './Sections/Header/Header';

function App() {
  return (
    <div className="app-container">
      <Header/>
      <AppRouter/>
    </div>
  );
}

export default App;
