import './About.scss';

const About = () => {
    return (
        <div className='page-wrappers'>
            <div className="blog-page-wrapper">
                <div className="blog-page-container">
                    <p>
                        My name is Bijun Jiang. I graduated from UC Davis in September 2021 with a major in Computer Science, and I'd like to give a brief self-introduction.
                    </p>
                    <p>
                        I grew up in a low-income community where <b>'technology'</b> was an unfamiliar concept. In my senior year of high school, I was gifted a laptop, marking my first real interaction with a computer, aside from using Microsoft Word to type essays. This laptop sparked my interest in Computer Science. I was intrigued by its intelligence and how the World Wide Web seemed to have an answer for almost everything. Consequently, when it was time to apply for college, I chose Computer Science as my preferred major.
                    </p>
                    <p>
                        Receiving the acceptance email from UC Davis signified the start of my journey in programming. To be honest, I felt lost during my undergraduate years, as I lacked prior experience in CS and didn’t have a clear career path. I completed all the required courses for graduation, including operating systems, computer languages, object-oriented programming, and more. Despite this, I was uncertain about my capabilities and future ambitions. Fortunately, I was employed as a part-time Computer Science instructor at Juni Learning, and this experience helped me develop a career goal. While teaching JavaScript courses, I discovered my passion for web development, which led to my current role as a web developer at Redcort Software Inc. On this blog, I plan to document my ongoing projects and achievements in the field, sharing my journey and the experiences I gain along the way.
                    </p>
                    {/* <p>One of my favorite book is <u>Hamlet</u>, because it contains the following quote:</p>
                    <blockquote>
                        "To be or not to be...that is the question" related to my future,
                        because I can choose who I want to be.
                    </blockquote> */}
                    <blockquote cite="https://www.devtopics.com/101-great-computer-programming-quotes/">
                        "Computers are useless.  They can only give you answers."
                        <p>-Pablo Picasso</p>
                    </blockquote>
                </div>
            </div>
        </div>
    )
}

export default About