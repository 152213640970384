import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "../Pages/AboutPage/About";
import Intro from "../Pages/Blogs/blog-pages/introduction/Intro";
import Home from "../Pages/HomePage/Home";

const AppRouter = () => {
  return (
    <Router>
      <div>
        {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
      </nav> */}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/intro" element={<Intro />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default AppRouter;
