import "./Blogs.scss";
const Blogs = () => {
  return (
    <div className="page-wrappers">
      <div className="blogs-container">
        <a className="blog-link" href="/intro">
          <div className="blog-card">
            <h3>Welcome</h3>
            <h6>Nov 1, 2023</h6>
            <p>
              This is the first blog of my site and I just wanted to say hi!
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Blogs;
