import "./Header.scss";
const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="max-width-1200 header-container">
        <section className="site-name">Bijun's Blog</section>
        <section></section>
        <section className="menu-section">
          <a href="/">Home</a>
          <a href="/about">About</a>
        </section>
      </div>
    </div>
  );
};
export default Header;
